<template id="content">
  <div class="border-bottom p-1" v-if="data == null">
    <router-link to="/" class="me-2">Start</router-link>/
    <span class="me-2">Wydawcy</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
    <h1 class="mt-2">Lista wydawców</h1>
    <small>Lista prezentuje sto pierwszych wierszy z wyszukiwania</small>

    <input
      type="text"
      class="form-control"
      v-model="filterValue"
      @keyup="doFilter"
      placeholder="Filtr: wpisz fragment nazwy"
    />
  </div>

  <div id="publishers">
    <div class="w-100 text-end mb-2">
      <span @click="createItem()" class="text-primary hand">Utwórz nowy</span>
    </div>
    <div v-if="edited === 0" class="p-3 border my-3">
      Tworzenie nowego elementu"
      <div class="col-md-10">
        <input
          type="text"
          maxlength="200"
          id="nazwa"
          class="w-75"
          placeholder="Nazwa wydawnictwa"
          v-model="publisher.nazwa"
        />
        <input
          type="text"
          maxlength="100"
          id="nazwa"
          class="w-25"
          placeholder="Miasto"
          v-model="publisher.miasto"
        />
      </div>
      <div class="col-md-2">
        <span @click="save(publisher)" class="text-primary hand">Ok</span>
        <span @click="cancel()" class="text-secondary hand ms-3">anuluj</span>
      </div>
    </div>

    <div
      class="row border-bottom"
      v-for="publisher in publishers"
      :key="publisher.id_wydawnictwa"
    >
      <template v-if="edited != publisher.id_wydawnictwa">
        <div class="col-md-8" title="Nazwa">
          <b>{{ publisher.nazwa }}</b> {{ publisher.miasto }}
        </div>
        <div class="col-md-4">
          <span v-if="data == null" @click="editInline(publisher)" class="text-primary hand">Edytuj</span>
          <span
            v-if="data != null"
            @click="selectPublisher(publisher)"
            class="text-primary hand fw-bold"
            >{{ publisher.nazwa }} [wybierz]</span
          >
        </div>
      </template>
      <template v-if="edited == publisher.id_wydawnictwa">
        <div class="col-md-10">
          <input
            type="text"
            maxlength="200"
            id="nazwa"
            class="w-75"
            placeholder="Nazwa wydawnictwa"
            v-model="publisher.nazwa"
          />
          <input
            type="text"
            maxlength="100"
            id="nazwa"
            class="w-25"
            placeholder="Miasto"
            v-model="publisher.miasto"
          />
        </div>
        <div class="col-md-2">
          <span @click="save(publisher)" class="text-primary hand">Ok</span>
          <span @click="cancel()" class="text-secondary hand ms-3">anuluj</span>
          <span
            @click="deleteItem(publisher)"
            class="text-secondary hand ms-3"
            title="Usuń"
            >&#9988;</span
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/services/FetchMachine";
import { showToast, customYesNo } from "@/services/Dialogs";

export default {
  name: "PublisherComplex",
  data() {
    return {
      publishers: [],
      publisher: null,
      isError: false,
      filterValue: "",
      edited: null,
    };
  },

  props: {
    //sprytne - dam tu obiekt
    data: {
      type: Object,
      default: null,
    },
  },

  created() {
    this.fetchPublishers();
  },

  methods: {
    doFilter(event) {
      console.log("Key up event:", event);
      this.fetchPublishers();
    },

    createItem() {
      fetchData("?section=publisher&action=empty", {}, (empty) => {
        this.publisher = empty;
        this.publisher.id_wydawnictwa = 0;
        this.edited = 0;
      });
    },

    editInline(obj) {
      console.log(obj);
      this.edited = obj.id_wydawnictwa;
    },

    cancel() {
      this.edited = null;
      this.fetchPublishers();
    },

    deleteItem(obj) {
      console.log(obj);
      customYesNo("Potwierdź czy chcesz usunąć pozycję", () => {
        fetchData(
          "?section=publisher&action=delete",
          { id: obj.id_wydawnictwa },
          (res) => {
            console.log(res);
            this.fetchPublishers();
          },
          (err) => {
            console.log(err);
            showToast(err, false, () => {
              location.reload();
            });
          }
        );
      });
    },

    save(obj) {
      console.log(obj);
      fetchData(
        "?section=publisher&action=save",
        { publisher: obj },
        (res) => {
          console.log(res);
          showToast("Wydawca zapisany", true, () => {
            this.edited = null;
            this.fetchPublishers();
          });
        },
        (err) => {
          console.log(err);
          showToast(
            "Błąd zapisu wydawcy. Upewnij się, że wypełniono dane prawidłowo",
            false
          );
        }
      );
    },

    selectPublisher(p) {
      if (this.data.callback != null) this.data.callback(p);
    },

    fetchPublishers() {
      fetchData(
        "?section=publisher&action=list",
        { filter: this.filterValue },
        (res) => {
          this.publishers = res;
        },
        (err) => {
          showToast(err, false);
        }
      );
    },
  },
};
</script>
