<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <h2 class="text-center mt-5">Logowanie</h2>
        <form>
          <div class="mb-3">
            <label for="login" class="form-label">Login</label>
            <input
              type="text"
              class="form-control"
              id="login"
              v-model="ident"
              placeholder="Podaj identyfikator domenowy"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Hasło</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
              placeholder="Podaj hasło"
            />
          </div>
          <button type="button" class="btn btn-primary w-100" @click="login">Zaloguj</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import {fetchData} from "@/services/FetchMachine";
import {showToast} from "@/services/Dialogs" //customYesNo


export default {
  name: "LoginScreen",
  data() {
    return {
      ident: "",
      password: ""
    };
  },
  
  methods: {
    login() {
      fetchData(        
        "?section=login&action=do",
        { login: this.ident, password: this.password },
        (res) => {
          console.log(res);
          localStorage.setItem("i", res.identyfikator);
          localStorage.setItem("r", res.rola);
          
          location.href="/";
        },
        () => {
          showToast("Błądny login lub hasło.", false);
        }, true
      );
    }
  }
};
</script>