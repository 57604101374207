<template>
  <h1 class="my-4">Dzień dobry!</h1>
  <div class="row row-cols-1 row-cols-md-2 g-4">
    <div class="col" v-if="canSee('grm')">
      <div class="card">
        <img src="assets/banner-1.jpg" class="card-img-top hand" alt="" onclick="location.href='/entries'"/>
        <div class="card-body">
          <h5 class="card-title">Akcesje</h5>
          <p class="card-text">Przeglądaj akcesje.</p>
          <router-link to="/entries" class="d-flex flex-end">Przejdź</router-link>
        </div>
      </div>
    </div>
    <div class="col" v-if="canSee('grm')">
      <div class="card">
        <img src="assets/banner-2.jpg" class="card-img-top hand" alt="" onclick="location.href='/clients'"/>
        <div class="card-body">
          <h5 class="card-title">Kontrahenci</h5>
          <p class="card-text">Przeglądaj kontrahentów oraz darczyńców.</p>
          <router-link to="/clients" class="d-flex flex-end">Przejdź</router-link>
        </div>
      </div>
    </div>
    <div class="col" v-if="canSee('grm, opr, mag, piu')">
      <div class="card">
        <img src="assets/banner-5.jpg" class="card-img-top hand" alt="" onclick="location.href='/protocols'"/>
        <div class="card-body">
          <h5 class="card-title">Protokoly</h5>
          <p class="card-text">Przeglądaj kontrahentów oraz darczyńców.</p>
          <router-link to="/protocols" class="d-flex flex-end">Przejdź</router-link>
        </div>
      </div>
    </div>

   <div class="col" v-if="canSee('grm')">
      <div class="card">
        <img src="assets/banner-3-k.jpg" class="card-img-top hand" alt="" onclick="location.href='/documents/k'"/>
        <div class="card-body">
          <h5 class="card-title">Kup do zbiorów</h5>
          <p class="card-text">Utwórz dokument kupna zbioru.</p>
          <router-link to="/documents/k" class="">Przejdź</router-link>
        </div>
      </div>
    </div>
    <div class="col" v-if="canSee('grm')">
      <div class="card">
        <img src="assets/banner-3-d.jpg" class="card-img-top hand" alt="" onclick="location.href='/documents/d'"/>
        <div class="card-body">
          <h5 class="card-title">Podarunek do zbiorów</h5>
          <p class="card-text">Utwórz dokument darowizny zbioru.</p>
          <router-link to="/documents/d" class="">Przejdź</router-link>
        </div>
      </div>
    </div>
    <div class="col" v-if="canSee('grm')">
      <div class="card">
        <img src="assets/banner-3-w.jpg" class="card-img-top hand" alt="" onclick="location.href='/documents/w'"/>
        <div class="card-body">
          <h5 class="card-title">Wymiana zbiorów</h5>
          <p class="card-text">Utwórz dokument wymiany zbioru.</p>
          <router-link to="/documents/w" class="">Przejdź</router-link>
        </div>
      </div>
    </div>


    <div class="col" v-if="canSee('grm')">
      <div class="card">
        <img src="assets/banner-publishers.jpg" class="card-img-top hand" alt="" onclick="location.href='/publishers'"/>
        <div class="card-body">
          <h5 class="card-title">Wydawnictwa</h5>
          <p class="card-text">
            Zarządzaj bazą wydawnictw.
          </p>
          <router-link to="/publishers" class="d-flex flex-end">Przejdź</router-link>
        </div>
      </div>
    </div>

    <div class="col" v-if="canSee('grm')">
      <div class="card">
        <img src="assets/banner-series.jpg" class="card-img-top hand" alt="" onclick="location.href='/series'"/>
        <div class="card-body">
          <h5 class="card-title">Serie</h5>
          <p class="card-text">
            Zarządzaj bazą serii.
          </p>
          <router-link to="/series" class="d-flex flex-end">Przejdź</router-link>
        </div>
      </div>
    </div>

    

    <div class="col" v-if="canSee('admin')">
      <div class="card">
        <img src="assets/banner-6.jpg" class="card-img-top hand" alt="" onclick="location.href='/users'"/>
        <div class="card-body">
          <h5 class="card-title">Administracja</h5>
          <p class="card-text">Zarządzaj kontami użytkoników.</p>

          <router-link to="/users" class="">Przejdź</router-link>
        </div>
      </div>
    </div>

    <div class="col" v-if="canSee('grm')">
      <div class="card">
        <img src="assets/banner-4.jpg" class="card-img-top hand" alt="" onclick="location.href='/reports'"/>
        <div class="card-body">
          <h5 class="card-title">Raporty</h5>
          <p class="card-text">
            Generuj raporty akcesji z podzialem na zakup, dary oraz wymiany.
          </p>
          <router-link to="/reports" class="d-flex flex-end">Przejdź</router-link>
        </div>
      </div>
    </div>


    
  </div>
</template>

<script>
import { checkSession } from "@/services/FetchMachine";

export default {
  name: "HomeScreen",
  created() {
    checkSession();
  },
  methods: {
    canSee(role) {
      if (localStorage.getItem("r") != null && role.includes(localStorage.getItem("r")))
        return true;
      else return false;
    },
  },
};
</script>
