import { createRouter, createWebHistory } from 'vue-router'
import HomeScreen from '../components/HomeScreen.vue'
import LoginScreen from '../components/LoginScreen.vue'
import ClientList from '../components/ClientList.vue'
import EntryList from '../components/EntryList.vue'
import EntryDetails from '../components/EntryDetails.vue'
import ClientDetails from '../components/ClientDetails.vue'
import DocumentDetails from '../components/DocumentDetails.vue'
import DocumentList from '../components/DocumentList.vue'
import ReportList from '../components/ReportList.vue'
import ProtocolList from '../components/ProtocolList.vue'
import ProtocolDetails from '../components/ProtocolDetails.vue'
import UserList from '../components/UserList.vue'
import PublisherComplex from '../components/PublisherComplex.vue'
import SeriesList from '../components/SeriesList.vue'
import SeriesDetails from '../components/SeriesDetails.vue'

const routes = [
  {
    path: '/Login',
    name: 'LoginScreen',
    component: LoginScreen
  },
  {
    path: '/',
    name: 'HomeScreen',
    component: HomeScreen
  },
  {
    path: '/reports',
    name: 'ReportList',
    component: ReportList,
    props: true 
  },
  {
    path: '/clients',
    name: 'ClientList',
    component: ClientList,
    props: true 
  },
  {
    path: '/client/:id',
    name: 'ClientDetails',
    component: ClientDetails,
    props: true 
  },
  {
    path: '/publishers',
    name: 'PublisherComplex',
    component: PublisherComplex    
  },
  {
    path: '/series',
    name: 'SeriesList',
    component: SeriesList    
  },
  {
    path: '/serie/:id',
    name: 'SeriesDetails',
    component: SeriesDetails,
    props: true 
  },
  {
    path: '/entries',
    name: 'EntryList',
    component: EntryList
  },
  {
    path: '/entry/:id',
    name: 'EntryDetails',
    component: EntryDetails,
    props: true 
  },
  {
    path: '/protocols',
    name: 'ProtocolList',
    component: ProtocolList
  },
  {
    path: '/protocol/:id',
    name: 'ProtocolDetails',
    component: ProtocolDetails
  },

  {
    path: '/users',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/documents/:kwd?',
    name: 'DocumentList',
    component: DocumentList,    
    props: true 
  },
  {
    path: '/document/:kwd?/:id',
    name: 'DocumentDetails',
    component: DocumentDetails,
    props: true 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


