<template>
  <div class="border-bottom p-1">
    <router-link to="/" class="me-2">Start</router-link>/
    <span class="me-2">Akcesje</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
  <h1 class="mt-2">Lista akcesji</h1>
  <small>Lista prezentuje sto pierwszych wierszy z wyszukiwania</small>

  <input
    type="text"
    class="form-control"
    v-model="filterValue"
    @keyup="doFilter"
    placeholder="Filtr: wpisz fragment tytułu, wydawcy, miejsa wydania, ISBN"
  />
  </div>

  
  <div id="content">
  
    <div class="row border-bottom" v-for="entry in entries" :key="entry.id_akc">
      <div class="col-md-2" title = "Protokół akcesji &#10;ISBN">
        {{ entry.prot_akc }}<br />{{ entry.isbn }}        
      </div>
      
      <div class="col-md-6" title="Tytuł | Seria&#10;Wydawnictwo | miejsce wydania | rok wydania ">
      
      <router-link  v-if="data==null" class="fw-bold" :to="{ name: 'EntryDetails', params: { id: entry.id_akc } }">{{ entry.tytul_woluminu }}</router-link>
      <i v-if="entry.nazwa_serii!=null && entry.nazwa_serii.length>1">&nbsp;{{entry.nazwa_serii}}</i>
      <span v-if="data!=null" @click="selectItem(entry)" class="text-primary hand fw-bold">{{entry.tytul_woluminu}} [wybierz]</span>

        <br />{{ entry.wydawnictwo }} | {{entry.miejsce_wydania}} | {{entry.rok_wydania}}
      </div>
      <div class="col-md-4" title= "Autor woluminu &#10;Data wprowadzenia">
        <i>{{ entry.autor_woluminu }}</i><br />{{ entry.data }}
        <br/>        
      </div>
      
    </div>
  </div>
</template>

<script>
import {fetchData} from "@/services/FetchMachine";
import {showToast} from "@/services/Dialogs" //customYesNo

export default {
  name: "EntryList",
  data() {
    return {
      entries: [],
      filterValue: "",
    };
  },
  props: { //sprytne - dam tu obiekt
    data: {
      type: Object,
      default: null
    }
  },
  created() {
    this.fetchClients();
  },
  methods: {
    doFilter(event) {
      console.log("Key up event:", event);
      this.fetchClients();
    },

    selectItem(item) {      
      if (this.data.callback!=null) this.data.callback(item);
    },


    fetchClients() {
      var action = "list";
        if (this.data!=null && this.data.action=="select-without-protocol") {
          action = "select-without-protocol";
        }
      fetchData(        
        "?section=entry&action=" + action,
        { filter: this.filterValue },
        (res) => {
          this.entries = res;
        },
        (err) => {
          if (err==null) err="Błąd pobierania danych, zaloguj się ponownie";
          showToast(err, false);
        }
      );
    },
  },
};
</script>
