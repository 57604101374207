<template>
    <div class="border-bottom p-1" v-if="data==null">
        <router-link to="/" class="me-2">Start</router-link>/
        <router-link to="/series" class="me-2">Serie</router-link>/
        <span class="me-2">Seria</span>
    </div>
    <h1 class="mt-4">Seria</h1>

    <div class="w-100 text-end">
        <button v-if="!isEditMode" @click="setEditMode(true)" class="btn btn-sm btn-outline-primary mb-3">
        Edytuj
        </button>
        <button v-if="isEditMode" @click="deleteSerie()" class="btn btn-sm btn-outline-danger mb-3 me-3">Usuń</button>
        <button v-if="isEditMode" @click="abortChanges()" class="btn btn-sm btn-outline-success mb-3 me-3">Anuluj</button>
        <button v-if="isEditMode" @click="saveSerie()" class="btn btn-sm btn-outline-primary mb-3">Zapisz</button>
    </div>
    <form>      
      <div class="mb-3">
        <label for="tytul_serii" class="form-label">Tytul serii</label>
        <input type="text" id="tytul_serii" class="form-control" v-model="serie.tytul_serii" :readonly="!isEditMode" :disabled="!isEditMode"/>
      </div>
      <div class="mb-3">
        <label for="tytul_tomu" class="form-label">Tytuł tomu </label>
        <input type="text" id="tytul_tomu " class="form-control" v-model="serie.tytul_tomu " :readonly="!isEditMode" :disabled="!isEditMode"/>        
      </div>
      <div class="mb-3">
        <label for="autorzy_tomu" class="form-label">Autorzy tomu</label>
        <textarea id="autorzy_tomu" class="form-control" v-model="serie.autorzy_tomu" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>        
      </div>
      <div class="mb-3">
        <label for="redaktor" class="form-label">Redaktor </label>
        <input type="text" id="redaktor " class="form-control" v-model="serie.redaktor " :readonly="!isEditMode" :disabled="!isEditMode">        
      </div>
      <div class="mb-3">
        <label for="nazwa_wydawnictwa" class="form-label">Wydawnictwo</label> 
        <span v-if="isEditMode" class="hand" @click="togglePublisherCombo">&#128270;</span>
        <AjaxCombo action="publisher" @item-selected="publisherSelected" v-if="isPublisherComboVisible"/>
        <textarea id="nazwa_wydawnictwa" class="form-control" v-model="serie.nazwa_wydawnictwa" disabled='disabled'></textarea>        
      
      </div>

      <div class="row">
         <div class="mb-3 col-md-4">
            <label for="rok_wydania" class="form-label">Rok wydania</label>
            <input type="number" id="rok_wydania" class="form-control" v-model="serie.rok_wydania" :readonly="!isEditMode" :disabled="!isEditMode"/>
        </div>
        <div class="mb-3 col-md-4">
            <label for="nr_kolejny_serii" class="form-label">Nr kolejny serii</label>
            <input type="number" id="nr_kolejny_serii" class="form-control" v-model="serie.nr_kolejny_serii" :readonly="!isEditMode" :disabled="!isEditMode"/>
        </div>
        <div class="mb-3 col-md-4">
            <label for="numer_akcesji" class="form-label">Nr akcesji</label>
            <input type="text" id="numer_akcesji" class="form-control" v-model="serie.numer_akcesji" disabled='disabled'>
        </div>

        <div class="mb-3 col-md-6">
            <label for="lokalizacja" class="form-label">Lokalizacja</label>
            <input type="text" id="lokalizacja" class="form-control" v-model="serie.lokalizacja" :readonly="!isEditMode" :disabled="!isEditMode"/>
        </div>

        <div class="mb-3 col-md-6">
            <label for="zakonczone" class="form-label">Zakonczone</label>
            <select type="text" id="nip" class="form-select" v-model="serie.zakonczone" :readonly="!isEditMode" :disabled="!isEditMode">        
            <option value="0">Nie</option>
            <option value="1">Tak</option>
            </select>
        </div>
      </div>

      <div class="mb-3">
        <label for="uwagi_do_akcesji" class="form-label">Uwagi do akcesji</label>
        <textarea id="uwagi_do_akcesji" class="form-control" v-model="serie.uwagi_do_akcesji" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>        
      </div>
      <div class="mb-3">
        <label for="uwagi_do_serii" class="form-label">Uwagi do serii</label>
        <textarea id="uwagi_do_serii" class="form-control" v-model="serie.uwagi_do_serii" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>        
      </div>

      <div class="row">
        <div class="mb-3 col-md-6">
            <label for="ostatnia_modyfikacja" class="form-label">Ostatnia modyfikacja</label>
            <input type="text" id="nip" class="form-control" v-model="serie.ostatnia_modyfikacja" disabled='disabled'>        
        </div>
        <div class="mb-3 col-md-6">
            <label for="zakonczone" class="form-label">Zakończone</label>
            <select type="text" id="nip" class="form-select" v-model="serie.zakonczone" :readonly="!isEditMode" :disabled="!isEditMode">        
            <option value="0">Nie</option>
            <option value="1">Tak</option>
            </select>
        </div>
      </div>
    </form>

  
</template>
<script>
import {fetchData} from "@/services/FetchMachine";
import { showToast, customYesNo } from "@/services/Dialogs";
import AjaxCombo from "../components/AjaxCombo.vue";
//import PublisherComplex from "../components/PublisherComplex.vue";

export default {
  name: "SerieDetails",
  data() {
    return {
        isEditMode: false,
        serie: {},
        isPublisherComboVisible: false
    };
  },
   components: {
    AjaxCombo
  },

  
  
  created() {
    this.fetchDetails();    
  },
  methods: {
    setEditMode(v) {
        this.isEditMode = v;
    },

    publisherSelected(v) {
      if (this.isEditMode) {
        console.log(v.id);
        this.serie.nazwa_wydawnictwa = v.name;
        this.serie.id_wydawnictwa = v.id;
        this.isPublisherComboVisible = false;
      }       
    },

    togglePublisherCombo() {
      this.isPublisherComboVisible = !this.isPublisherComboVisible;
    },

    saveSerie() {
        fetchData(
            "?section=serie&action=save",
            {serie:this.serie},
             (res) => {
                this.serie = res;
                showToast("Dane zapisane", true);
            },
            () => {
                showToast("Błąd zapisu", false);
            }
        );
    },

    abortChanges() {
        this.setEditMode(false);
        this.fetchDetails(this.serie.id_seria);
    },

    deleteSerie() {
      customYesNo("Potwierdź czy chcesz usunąć serie?",()=>{

        fetchData(
            "?section=serie&action=delete", { id: this.serie.id_seria },        
        (res) => {
          console.log(res);
          location.href= "/series";
        }, 
        (err) => {
          console.log(err);
          showToast(err, false, ()=>{location.reload();});          
          }
          );      
      });
    },
    
    fetchDetails(id = this.$route.params.id) {        
        var action = "details";
        console.log("id", id);

        if (id==null || id=="0") {
            id=0;
            this.isEditMode=true;
            action = "empty";
        }
        fetchData(
            "?section=serie&action="+action,
            { id: id},
            (res) => {
                this.serie = res;
            },
            () => {
                showToast("Błąd pobierania danych", false);
            }
        );
    },
            

  },
};
</script>
