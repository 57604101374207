<template>
    <h1 v-if="!isPopup" class="pt-4">Akcesja  {{entry.prot_akc}}</h1>
    <div class="w-100 text-end " v-if="!isPopup">    
        <router-link v-if="entry.nabycie_id_fvat>0" class="me-5" :to="{ name: 'DocumentDetails', params: { kwd:entry.kwd, id: entry.nabycie_id_fvat} }">przejdź do dokumentu źródłowego..</router-link>
        <button @click="toggleEditMode" class="btn btn-outline-primary mb-3 d-none">
        {{ isEditMode ? 'Zapisz' : 'Edytuj' }}
        </button>      
    </div>

    <form>
      <div class="row">
      <div class="mb-1 col-md-4">
            <label for="isbn" class="form-label">ISBN</label>
            <span v-if="isEditMode" class="hand" @click="callApi">&#127760;</span>                        
            <input type="text" id="isbn" class="form-control" v-model="entry.isbn" :readonly="!isEditMode" :disabled="!isEditMode">
        </div>
        <div class="mb-1 col-md-4">
            <label for="prot_akc" class="form-label">Protokół akcesji</label>
            <input type="text" id="prot_akc" class="form-control" v-model="entry.prot_akc" :readonly="true" :disabled="true">
        </div>
      </div>

      <div class="mb-3">
        <label for="autor_woluminu" class="form-label">Autor Woluminu</label>
        <span v-if="isEditMode" class="hand" @click="toggleAuthorCombo">&#128270;</span>
        <AjaxCombo action="author" @item-selected="authorSelected" v-if="isAuthorComboVisible"/>
        <input type="text" id="autor_woluminu" class="form-control" v-model="entry.autor_woluminu" :readonly="!isEditMode" :disabled="!isEditMode">
        <d-keyboard v-if="isEditMode" keyboard-for="autor_woluminu"></d-keyboard>
      </div>
      <div class="mb-3">
        <label for="tytul_woluminu" class="form-label">Tytuł woluminu</label>
        <textarea id="tytul_woluminu" class="form-control" v-model="entry.tytul_woluminu" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>
        <d-keyboard v-if="isEditMode" keyboard-for="tytul_woluminu"></d-keyboard>
      </div>

      <div class="mb-3">
        <label for="nazwa_serii" class="form-label">Nazwa serii</label>
        <span v-if="isEditMode" class="hand" @click="toggleSerieCombo">&#128270;</span>
        <AjaxCombo action="series" @item-selected="serieSelected" v-if="isSerieComboVisible"/>        
        <textarea id="nazwa_serii" class="form-control" v-model="entry.nazwa_serii" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>
      </div>

      
      <div class="mb-3">
        <label for="wydawnictwo" class="form-label">Wydawnictwo</label> 
        <span v-if="isEditMode" class="hand" @click="togglePublisherCombo">&#128270;</span>
        <AjaxCombo action="publisher" @item-selected="publisherSelected" v-if="isPublisherComboVisible"/>
        <textarea id="wydawnictwo" class="form-control" v-model="entry.wydawnictwo" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>
        <d-keyboard v-if="isEditMode" keyboard-for="wydawnictwo"></d-keyboard>
      </div>
      <div class="mb-3">
        <label for="miejsce_wydania" class="form-label">Miejsce wydania</label>
        <textarea id="miejsce_wydania" class="form-control" v-model="entry.miejsce_wydania" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>
        <d-keyboard v-if="isEditMode" keyboard-for="miejsce_wydania"></d-keyboard>
      </div>

      <div class="row">
        <div class="mb-1 col-md">      
            <label for="rok_wydania" class="form-label">Rok wydania</label>
            <input type="number" id="rok_wydania" class="form-control" v-model="entry.rok_wydania" :readonly="!isEditMode" :disabled="!isEditMode">
        </div>

        <div class="mb-1 col-md"> 
            <label for="liczba_woluminow" class="form-label" >Liczba woluminów</label>
            <input type="number" id="liczba_woluminow" class="form-control"   v-model="entry.liczba_woluminow" :readonly="!isEditMode" :disabled="!isEditMode">
        </div>

        <div class="mb-1 col-md"> 
            <label for="tom" class="form-label" >Tom</label>
            <input type="number" id="tom" class="form-control"   v-model="entry.liczba_woluminow" :readonly="!isEditMode" :disabled="!isEditMode">
        </div>        
        
      </div>


      
      <div class="mb-3">
        <label for="uwagi" class="form-label">Uwagi</label>
        <textarea id="uwagi" class="form-control" v-model="entry.uwagi" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>
      </div>
      
      <div class="row">
        

        <div class="mb-1 col-md" v-if="modeNettoBrutto!=null">
            <label for="cena_poczatkowa_nb" class="form-label">Cena początkowa {{modeNettoBruttoName}}</label>
            <input type="number" step="0.01" id="cena_poczatkowa_nb" class="form-control" @change="recalc()" v-model="entry.cena_poczatkowa_nb" :readonly="!isEditModeFa" :disabled="!isEditModeFa">
        </div>
        <div class="mb-1 col-md" v-if="modeNettoBrutto!=null">
            <label for="rabat_jednostkowy" class="form-label">Rabat jednostkowy</label>
            <input type="number" step="0.01" id="rabat_jednostkowy" class="form-control"  @change="rabatChanged()" v-model="entry.rabat_jednostkowy" :readonly="!isEditModeFa" :disabled="!isEditModeFa">
        </div>

        <div class="mb-1 col-md" v-if="modeNettoBrutto!=null">
            <label for="rabat_procentowy" class="form-label">Rabat %</label>
            <input type="number" step="0.01" id="rabat_procentowy" class="form-control"  @change="rabatProcChanged()" v-model="entry.rabat_procentowy" :readonly="!isEditModeFa" :disabled="!isEditModeFa">
        </div>

        <div class="mb-1 col-md">
            <label for="stawka_vat" class="form-label" >Stawka VAT</label>            
            <select class="form-select" @change="recalc()" v-model="entry.stawka_vat" :readonly="!isEditModeFa" :disabled="!isEditModeFa">              
              <option value="0">0%</option> 
              <option value="5">5%</option> 
              <option value="8">8%</option> 
              <option value="23">23%</option> 
              <option value="-1">np</option> 
              <option value="-2">zw</option> 
              <option value="-9">-</option> 
            </select>
        </div>
      </div>
      <div class="row">
        <div class="mb-1 col-md">
            <label for="cena_jednostkowa_netto" class="form-label" >Cena netto</label>
            <input type="number" step="0.01" id="cena_jednostkowa_netto" @change="bruttoNettoChanged()" class="form-control" v-model="entry.cena_jednostkowa_netto"  :readonly="!isEditModeFa" :disabled="!isEditModeFa || modeNettoBrutto==1">
        </div>
                       
        <div class="mb-1 col-md">
            <label for="cena_jednostkowa_brutto" class="form-label" >Cena brutto</label>
            <input type="number" step="0.01" id="cena_jednostkowa_brutto" @change="bruttoNettoChanged()" class="form-control" v-model="entry.cena_jednostkowa_brutto"  :readonly="!isEditModeFa" :disabled="!isEditModeFa || modeNettoBrutto==0">
        </div>

        <div class="mb-1 col-md">
            <label for="wartosc_netto" class="form-label" >Wartość netto</label>
            <input type="number" step="0.01" id="wartosc_netto" class="form-control" @blur="recalcTotal()" v-model="entry.wartosc_netto"  :readonly="!isEditModeFa" :disabled="!isEditModeFa  || modeNettoBrutto==1">
        </div>
                       
        <div class="mb-1 col-md">
            <label for="wartosc_brutto" class="form-label">Wartość brutto</label>
            <input type="number" step="0.01" id="wartosc_brutto" class="form-control" @blur="recalcTotal()" v-model="entry.wartosc_brutto"  :readonly="!isEditModeFa" :disabled="!isEditModeFa  || modeNettoBrutto==0">
        </div>         
      </div>

      <div class="row">
        <div class="mb-1 col-md-6"> 
            <label for="ksiega" class="form-label">Księga</label>
            <input type="text" id="ksiega" class="form-control" v-model="entry.ksiega" :readonly="true" :disabled="true">
        </div>
        
        <div class="mb-1 col-md-6">
            <label for="data" class="form-label">Data i czas wpisu</label>
            <input type="datetime-local" id="data" class="form-control" v-model="entry.data" :readonly="true" :disabled="true">
        </div>
        

      </div>          
    </form>
  
</template>
<script>
import {fetchData} from "@/services/FetchMachine";
import AjaxCombo from "../components/AjaxCombo.vue";
import {showToast} from "@/services/Dialogs"

export default {
  name: "EntryDetails",
  data() {
    return {
        isEditMode: false,
        isEditModeFa: false,
        isPopup: false,
        isSerieComboVisible : false,
        isPublisherComboVisible : false,
        isAuthorComboVisible : false,
        modeNettoBrutto: null, //0 / 1
        modeNettoBruttoName: "", //tylko opis
        entry: {},      
    };
  },

  components: {
    AjaxCombo
  },

  props: { //sprytne - dam tu obiekt
    data: {
      type: Object,
      default: null
    }
  },
  
  created() {
    if (!this.data) {
        this.fetchEntry();
    } else {
        this.isPopup = true;
        console.log("DATA in ENTRY",this.data);
        this.entry = this.data.entry;
        if (this.data.platnikVat=="0") {
            this.modeNettoBrutto = 1;
            this.modeNettoBruttoName="brutto";
        }
        if (this.data.platnikVat=="1") {
            this.modeNettoBrutto = 0;
            this.modeNettoBruttoName="netto";
        }
        if (this.data.action=="edit") {
            this.isEditMode = true;
            this.isEditModeFa = true;
        }
        this.calcRabatProc();
    }    
  },
  methods: {
    recalc() {
      console.log("Recalc");
      var vatVal = this.entry.stawka_vat;
            
      if (vatVal > 0) vatVal = 1.0 + vatVal / 100.0; else vatVal = 1.0;
      if (this.modeNettoBrutto===1) { //brutto
        //if (this.cena_poczatkowa_nb==null) this.cena_poczatkowa_nb = this.entry.cena_jednostkowa_brutto;
        this.entry.cena_jednostkowa_brutto = (this.entry.cena_poczatkowa_nb - this.entry.rabat_jednostkowy).toFixed(2);
        this.entry.cena_jednostkowa_netto = (this.entry.cena_jednostkowa_brutto / vatVal).toFixed(2);
        this.entry.wartosc_brutto = (this.entry.cena_jednostkowa_brutto * 1).toFixed(2);
        this.entry.wartosc_netto = (this.entry.wartosc_brutto / vatVal).toFixed(2);
      } 
      if (this.modeNettoBrutto===0) {
        //if (this.cena_poczatkowa_nb==null) this.cena_jednostkowa_netto = this.entry.cena_jednostkowa_netto;
        this.entry.cena_jednostkowa_netto = (this.entry.cena_poczatkowa_nb - this.entry.rabat_jednostkowy).toFixed(2);
        this.entry.cena_jednostkowa_brutto = (this.entry.cena_jednostkowa_netto * vatVal).toFixed(2);
        this.entry.wartosc_netto = (this.entry.cena_jednostkowa_netto * 1).toFixed(2);
        this.entry.wartosc_brutto = (this.entry.wartosc_netto * vatVal).toFixed(2);
      }
      this.calcRabatProc();
    },

    calcRabatProc() {
      this.entry.rabat_procentowy = 0;
      if (this.modeNettoBrutto===1 && this.entry.cena_jednostkowa_brutto > 0) { //brutto
        this.entry.rabat_procentowy = 100.0 * (1.0 - this.entry.cena_jednostkowa_brutto / this.entry.cena_poczatkowa_nb);
      }
      if (this.modeNettoBrutto===0 && this.entry.cena_jednostkowa_netto > 0) { //brutto
        this.entry.rabat_procentowy = 100.0 * (1.0 - this.entry.cena_jednostkowa_netto / this.entry.cena_poczatkowa_nb);
      }
      this.entry.rabat_procentowy = this.entry.rabat_procentowy.toFixed(2);
    },

    rabatChanged() {
      this.recalc();
    },

    rabatProcChanged() {
      //console.log(isNaN(this.entry.rabat_procentowy), this.entry.rabat_procentowy, this.entry.cena_poczatkowa_nb);
      if (isNaN(this.entry.rabat_procentowy)==false && this.entry.cena_poczatkowa_nb>0) {
        this.entry.rabat_jednostkowy = this.entry.cena_poczatkowa_nb - (this.entry.cena_poczatkowa_nb * this.entry.rabat_procentowy/100.0);
        this.entry.rabat_jednostkowy = this.entry.rabat_jednostkowy.toFixed(2);
        
      } else {
        //this.e
      }
    },

    bruttoNettoChanged() {      
      if (this.modeNettoBrutto===1) { //brutto                
        this.entry.rabat_jednostkowy = (this.entry.cena_poczatkowa_nb - this.entry.cena_jednostkowa_brutto).toFixed(2);
      } 
      if (this.modeNettoBrutto===0) { //netto
        this.entry.rabat_jednostkowy = (this.entry.cena_poczatkowa_nb - this.entry.cena_jednostkowa_netto ).toFixed(2);
      }
      this.recalc();
    },

    

    recalcTotal() {      
      console.log("Recalt total", this.cena_poczatkowa_nb);
      
      /*if (this.entry.liczba_woluminow==0) {
          this.entry.cena_jednostkowa_brutto = this.entry.cena_jednostkowa_netto = 0;
      } else {
*/
        if (this.modeNettoBrutto===1) { //brutto              
          this.entry.cena_jednostkowa_brutto = (this.entry.wartosc_brutto / 1).toFixed(2);
          if (this.entry.cena_poczatkowa_nb==undefined) this.entry.cena_poczatkowa_nb = this.entry.cena_jednostkowa_brutto;
        } 
        if (this.modeNettoBrutto===0) { //netto
          this.entry.cena_jednostkowa_netto = (this.entry.wartosc_netto / 1).toFixed(2);
          if (this.entry.cena_poczatkowa_nb==undefined) this.entry.cena_poczatkowa_nb = this.entry.cena_jednostkowa_netto;
        }
  //    }
      this.bruttoNettoChanged();
    },

    callApi() {
      fetchData(
        "?section=nb_api",
        { isbn: this.entry.isbn},
        (res) => {
            console.log(res)
            var obj = res;
            this.entry.wydawnictwo = obj.wydawnictwo;
            this.entry.wydawnictwo_id = obj.wydawnictwo_id;
            this.entry.autor_woluminu = obj.autor_woluminu;
            this.entry.miejsce_wydania = obj.miejsce_wydania;
            this.entry.rok_wydania = obj.rok_wydania;
            this.entry.tytul_woluminu = obj.tytul_woluminu;          
        },
        (err) => {
          console.log(err);
          showToast(err, false);
        }
      );
      /*
      fetch("https://data.bn.org.pl/api/networks/bibs.json?isbnIssn=9788328903913&amp;limit=1")
      .then(res => res.json())
      .then(res => {
        if (res.bibs.length>0) {
            var obj = res.bibs[0];
            this.entry.wydawnictwo = obj.publisher;
            this.entry.autor_woluminu = obj.author;
            this.entry.rok_wydania = obj.publicationYear;
            this.entry.tytul_woluminu = obj.title;
            console.log(obj);
        } else {
          showToast("Brak podanego nr ISBN w bazie", false);
        }
        //console.log(res.bibs);
      }).catch(error => {
        console.log("Błąd: ", error);
        showToast("Błąd komunikacji z serwerem Biblioteki Narodowej", false);
      })
      */    
    },

    toggleSerieCombo() {
      this.isSerieComboVisible = !this.isSerieComboVisible;
    },

    publisherSelected(v) {
      if (this.isEditMode) {
        this.entry.wydawnictwo = v.name;
        this.entry.id_wydawnictwo = v.id;
        this.isPublisherComboVisible = false;
      }       
    },

    serieSelected(v) {
      if (this.isEditMode) {
        this.entry.nazwa_serii = v.tytul_serii;
        this.entry.id_serii = v.id;
        this.entry.wydawnictwo = v.nazwa_wydawnictwa;
        this.entry.id_wydawnictwo = v.id_wydawnictwa;
        this.isSerieComboVisible = false;
      }       
    },

    toggleEditMode() {
       this.isEditMode = !this.isEditMode;
       if (!this.isEditMode) {
          this.isPublisherComboVisible = false;
          this.isAuthorComboVisible = false;
          this.isSerieComboVisible = false;
       }
          //this.isEditModeFa = !this.isEditModeFa;
    },

    togglePublisherCombo() {
      this.isPublisherComboVisible = !this.isPublisherComboVisible;
    },

    authorSelected(v) {
      if (this.isEditMode) {
        this.entry.autor_woluminu = v.name;
        this.isAuthorComboVisible = false;
      }       
    },    

    toggleAuthorCombo() {
      this.isAuthorComboVisible = !this.isAuthorComboVisible;
    },
    
    fetchEntry() {
      fetchData(
        "?section=entry&action=details",
        { id: this.$route.params.id },
        (res) => {
          this.entry = res;
          this.calcRabatProc();
        },
        () => {
          //document.getElementById("content").innerHtml = "X";
          //$("#clients").html("Błąd");
        }
      );
    },
  },
};
</script>

