<template id="content">
  <div class="border-bottom p-1" v-if="data==null">
    <router-link to="/" class="me-2">Start</router-link>/
    <span class="me-2">Klienci</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
    <h1 class="mt-2">Lista klientów</h1>
    <small>Lista prezentuje sto pierwszych wierszy z wyszukiwania</small>

    <input
      type="text"
      class="form-control"
      v-model="filterValue"
      @keyup="doFilter"
      placeholder="Filtr: wpisz fragment nazwy, adresu lub NIP"
    />
  </div>

  <div id="clients">
    <div class='w-100 text-end'>
      <router-link class="btn btn-outline-primary" :to="{ name: 'ClientDetails', params: { id: 0 } }">Utwórz klienta</router-link>
    </div>
    <div class="row border-bottom" v-for="client in clients" :key="client.id_knt">
      <div class="col-md-6" title="Nazwa&#10;NIP">
        <router-link v-if="data==null" class="fw-bold" :to="{ name: 'ClientDetails', params: { id: client.id_knt } }">{{ client.nazwa_wystawcy }}</router-link>
        <span v-if="data!=null" @click="selectKnt(client)" class="text-primary hand fw-bold">{{client.nazwa_wystawcy}} [wybierz]</span>
        <br />{{ client.nip }}
      </div>
      <div class="col-md-6" title = "Adres">
        {{ client.adres_wystawcy }}<br />{{ client.adres_wystawcy_cd }}
      </div>      
    </div>
  </div>
</template>

<script>
import {fetchData} from "@/services/FetchMachine";
import {showToast} from "@/services/Dialogs"

export default {
  name: "ClientList",
  data() {
    return {
      clients: [],
      isError : false,
      filterValue: ""
    };
  },
  
  props: { //sprytne - dam tu obiekt
    data: {
      type: Object,
      default: null
    }
  },

  created() {
    this.fetchClients();
  },

  methods: {
    doFilter(event) {
      console.log("Key up event:", event);
      this.fetchClients();
    },

    selectKnt(client) {      
      if (this.data.callback!=null) this.data.callback(client);
    },

    fetchClients() {
      fetchData(
        "?section=client&action=list",
        { filter: this.filterValue },
        (res) => {
          this.clients = res;
        },
        (err) => {          
            showToast(err, false);
        }
      );
    },
  },
};
</script>
