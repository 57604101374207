<template id="content">
  <div class="border-bottom p-1">
    <a href="/" class="me-2">Start</a>/
    <span class="me-2">Protokoły</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
    <h1 class="mt-2">Lista protokołów</h1>    
    <div class="row">
        <div class="col-md-4">
        Typ
        <select class="form-select" v-model="selectedMode" @change="handleChange">
          <option value="">-dowolny-</option>
          <option value="grm">W gromadzeniu</option>
          <option value="opr">W oprawianiu</option>
          <option value="kat">W inwentaryzacji</option>
          <option value="kat">W katalogowaniu</option>
          <option value="piu">W przechowywaniu</option>
          <option value="mag">W magazynie</option>
        </select>
      </div>
      <div class="col-md-4">
        Rok
        <select class="form-select" v-model="selectedYear" @change="handleChange">
          <option v-for="y in years" :key="y">{{ y }}</option>
        </select>
      </div>
      <div class="col-md-4">
        Miesiąc
        <select class="form-select" v-model="selectedMonth" @change="handleChange">
          <option value="0">-dowolny-</option>
          <option value="1">styczeń</option>
          <option value="2">luty</option>
          <option value="3">marzec</option>
          <option value="4">kwiecień</option>
          <option value="5">maj</option>
          <option value="6">czerwiec</option>
          <option value="7">lipiec</option>
          <option value="8">sierpień</option>
          <option value="9">wrzesień</option>
          <option value="10">październik</option>
          <option value="11">listopad</option>
          <option value="12">grudzień</option>
        </select>
      </div>
    </div>

<!--
    <input
      type="text"
      class="form-control mt-4"
      v-model="filterValue"
      @keyup="doFilter"
      placeholder="Filtr: wpisz fragment nazwy, adresu lub NIP"
    /> -->
  </div>

  <div class='w-100 text-end mb-3' v-if="isGrm()">
      <router-link class="btn btn-outline-primary" :to="{ name: 'ProtocolDetails', params: { id: 0 } }">Dodaj dokument</router-link>
  </div>

  <div id="documents">
    <div class="row border-bottom py-2" v-for="doc in documents" :key="doc.id_prot">
      <div class="col-md-6" title="Nr protokołu&#10;Nazwa">

        <router-link class="" :to="{ name: 'ProtocolDetails', params: { id: doc.id_prot } }">
          <b>{{doc.nr_pelny}}</b>
        </router-link><br/>
        <b v-if="doc.podtytul_prot">{{doc.podtytul_prot}}</b><b v-if="!doc.podtytul_prot">Protokół bez nazwy</b>
      </div>
       <div class="col-md-3" title="Data protokołu">
        {{ doc.data_operacji }}
      </div>      
      <div class="col-md-3" title="Status" v-html="getStatus(doc)"></div>      
    </div>
  </div>
  <h2 v-if="documents!=null && documents.length==0" >Brak elementów spełniających kryterium wyboru listy</h2>
</template>

<script>
import {fetchData} from "@/services/FetchMachine";
import {showToast, getProtocolStatus} from "@/services/Dialogs" //customYesNo

export default {
  name: "ProtocolList",
  data() {
    return {
      documents: null,
      isError: false,
      filterValue: "",
      years: [],
      selectedMode : '',
      selectedYear : new Date().getFullYear(),
      selectedMonth : new Date().getMonth()+1,
    };
  },
  

  created() {
    const currentYear = new Date().getFullYear();
    for (var a = currentYear; a > 2009; a--) {
      this.years.push(a);
    }
    console.log(this.years);
    this.fetchList();
  },

  methods: {
    doFilter(event) {
      console.log("Key up event:", event);
      this.fetchList();
    },

    getStatus(doc) {
      return getProtocolStatus(doc);
    },

    handleChange() {
      this.fetchList();
    },

    isGrm() {
      return localStorage.getItem('r')==='grm' ? true : false;
    },

    fetchList() {
      fetchData(
        "?section=protocol&action=list",
        { filter: this.filterValue, mode:this.selectedMode, year : this.selectedYear, month: this.selectedMonth },
        (res) => {
          this.documents = res;
        },
        (err) => {
          if (err==null) err="Błąd pobierania danych, zaloguj się ponownie";
          showToast(err, false);
        }
      );
    },
  },
};
</script>
