<template>
  <div>
    <input type="text" v-model="query"  @input="fetchHints" placeholder="Napisz minimum trzy znaki by szukać..." class="form-control"/>
    <ul v-if="results.length">
      <li v-for="item in results" :key="item.id" @click="selectItem(item)">{{ item.name }}</li>
    </ul>
  </div>
</template>

<script>

import {fetchData} from "@/services/FetchMachine";

export default {
//  name: "AjaxCombo",
  data() {
    return {
      query: '',
      ref : '',
      results: []
    };
  },
   props: { //sprytne - dam tu obiekt
    /*refid: {
      type: String,
      default: null
    },*/
    action: {
      type: String,
      default: "",
      required: true
    },
    refname: {
      type: String,
      default: null
    }
  },

  created() {
    if (this.refname!=null) {
        this.query = this.refname;
    }
  },

  methods: {

    fetchHints() {
      if (this.query.length < 3) {
        this.results = [];
        return;
      }

      fetchData(
            "?section=combo&action="+this.action+"&filter="+this.query,
            {},
             (res) => {
                this.results = res;                
            },
            () => {}
      )
    },
    selectItem(item) {
      this.query = item.name;
      this.results = [];
      item.refid = this.refid;
      this.$emit('item-selected', item);      
    },

  }
};
</script>

<style scoped>
ul {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 8px;
  cursor: pointer;
}

li:hover {
  background-color: #f0f0f0;
}
</style>
