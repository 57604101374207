<template>
  <div class="border-bottom p-1">
    <router-link to="/" class="me-2">Start</router-link>/
    <span class="me-2">Konta</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
    <h1 class="mt-2">Lista kont</h1>
  </div>

  <div id="content">
    <div class="row border-bottom my-2 fw-bold">
      <div class="col-md-3">Identyfikator domenowy</div>
      <div class="col-md-3">Rola</div>
      <div class="col-md-3">Nazwa</div>
    </div>

    <div class="row border-bottom py-2" v-for="(user, index) in users" :key="index">
      <div class="col-md-3">
      <input type="text" id="id" class="form-control" v-model="user.identyfikator" />
      
      </div>
      <div class="col-md-3">
        <select class="form-select" v-model="user.rola">
          <option value="adm">Admin</option>
          <option value="grm">Gromadzenie</option>
          <option value="kat">Katalogowanie</option>
          <option value="inw">Inwentarzyacja</option>
          <option value="opr">Oprawa</option>
          <option value="mag">Magazyn</option>
          <option value="piu">Przechowywanie</option>
          
        </select>
      </div>
      <div class="col-md-3">
        <input type="text" id="nazwa" class="form-control" v-model="user.nazwa" />
      </div>
      <div class="col-md-3">
        <button @click="saveUser(user)" class="btn btn-sm btn-outline-primary mx-1">
          Zapisz
        </button>
        <button @click="deleteUser(user)" class="btn btn-sm btn-outline-warning mx-1">
          Usuń
        </button>
      </div>      
    </div>
  </div>
  <button @click="addUser()" class="btn btn-sm btn-outline-primary mx-1 my-2">
          Dodaj użytkownika
      </button>
</template>

<script>
import { fetchData } from "@/services/FetchMachine";
import { showToast, customYesNo } from "@/services/Dialogs"; //customYesNo

export default {
  name: "UserList",
  data() {
    return {
      users: [],
    };
  },

  created() {
    this.fetchUsers();
  },
  methods: {

    addUser() {
      fetchData(
        "?section=user&action=empty", {},
        (empty) => {
          this.users.push(empty);
        });
    },

    saveUser(u) {
      console.log(u);
      fetchData(
        "?section=user&action=save",
        { user: u },
        (res) => {  
          console.log(res);
          showToast("Użytkownik zapisany", true, () => {
            this.fetchUsers();
          });
        },
        (err) => {
          console.log(err);
          showToast(
            "Błąd zapisu uzytkownika. Upewnij się, że podany identyfikator nie ma już konta oraz podano właście dane",
            false
          );
        }
      );
    },

    deleteUser(u) {
      customYesNo("Usunąć użytkownika?", () => {
        fetchData("?section=user&action=delete", { id: u.identyfikator }, (res) => {
          console.log(res);
          showToast("Użytkownik usunięty", true, () => {
            location.reload();
          });
        });
      });
    },

    fetchUsers() {
      var action = "list";
      fetchData(
        "?section=user&action=" + action,
        {},
        (res) => {
          this.users = res;
        },
        (err) => {
          if (err == null) err = "Błąd pobierania danych, zaloguj się ponownie";
          showToast(err, false);
        }
      );
    },
  },
};
</script>
